/*
  HRC COLORS
  Red     #993520
  Yellow  #F9B200
  Black   #000000
*/

.sidebar {
  padding: 50px 25px;
  width: 350px;
  height: 650px;
}

.my-nav {
  border: 2px solid #993520;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.my-input {
  border: 2px solid #993520;
  font-weight: bold;
  color: #993520;
}

.my-input:focus {
  border-color: #993520;
  color: #993520;
  box-shadow: none !important;
  font-weight: bold;
}

.form-control {
  height: 100%;
  margin-left: -1px;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 0.5;
}

.form-control:focus::placeholder {
  color: transparent;
}

.my-btn, .my-btn:hover, .my-btn:focus, .my-btn:active {
  background-color: #F9B200 !important;
  border: 2px solid #993520 !important;
  color: #993520 !important;
  font-weight: bold;
  box-shadow: none !important;
}

.list-group-item {
  border: 0;
  padding: 0.25rem 1rem;
}

.continent {
  border-bottom: 1px dashed #993520;
}

.badge {
  float: right;
  font-size: 100%;
  background-color: #F9B200;
  color: #993520;
}

.nav-top {
  border-bottom: 2px solid #993520;
}

.pin-attr {
  color: #993520;
  font-weight: bold;
}

.modal-title {
  margin: 0 auto;
  font-weight: bold;
  color: #993520;
}

.modal-body {
  margin: 0 auto;
}